/* eslint-disable import/no-unresolved */
// import Maintainence from '@assets/underMaintainence.svg';
import React from 'react';

import { COMING_SOON } from '@/config';

import coreyoLogo from '../../../assets/logo.svg';
import siteUnderMaintainence from '../../../assets/underMaintainence.svg';

export const SiteUnderMaintainence = () => {
  return (
    <div className="site__maintenancewrap">
      <img className="w-auto h-8 md:h-10 ml-3" alt="coreyoLogo" src={coreyoLogo} />
      <div
        className="container flex flex-col ml-auto mr-auto mt-4 justify-center"
        style={{ marginRight: 'auto', height: '75vh' }}
      >
        {/* <div>HI</div>siteUndermaintainence */}
        <div className="flex flex-col justify-center align-middle items-center">
          <img
            className=" h-auto"
            style={{ width: '35%' }}
            alt="maintainence"
            src={siteUnderMaintainence}
          />
          {COMING_SOON == 'false' ? (
            <div className="text-primary mt-4" style={{ fontSize: '3vw' }}>
              Website Under Maintainence
            </div>
          ) : (
            <div className="text-primary mt-4" style={{ fontSize: '3vw' }}>
              COMING SOON
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
