import { initReactQueryAuth } from 'react-query-auth';

import { Spinner } from '@/components/Elements';
import {
  loginWithEmailAndPassword,
  getUserProfile,
  registerWithEmailAndPassword,
  RegisterCredentials,
  loginWithGoogle,
  logoutWithGoogle,
  UserResponse,
  validateOtp,
} from '@/features/auth';
import storage from '@/utils/storage';

export const handleUserResponse = (data: UserResponse) => {
  storage.setToken(data.token);
  return data;
};
export const googleHandleUserResponse = (data: any) => {
  storage.setToken(data);
  return data;
};

async function loadUser() {
  if (storage.getToken()) {
    const data = await getUserProfile();
    return data.data.data;
  }
  return null;
}

async function loginFn(data: any) {
  if (data.provider === 'Google') {
    const response = await loginWithGoogle(data.token);
    const user = await handleUserResponse(response.data);
    return user;
  } else if (data.provider === 'OTP') {
    const value = {
      mobile_no: data.mobile_no,
      otp: data.otp,
    };
    const response = await validateOtp(value);
    const user = await handleUserResponse(response.data);
    return user;
  } else {
    const response = await loginWithEmailAndPassword(data);
    return await handleUserResponse(response.data);
  }
}

async function registerFn(data: RegisterCredentials) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  return await logoutWithGoogle().then((rep: any) => {
    if (rep.httpCode === 200) {
      storage.clearToken();
      window.location.assign(window.location.origin as unknown as string);
    }
  });
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spinner size="xl" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  UserResponse | null,
  unknown
  // Token,
  // LoginCredentials,
  // RegisterCredentials
>(authConfig);
