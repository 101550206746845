import { axios } from '@/lib/axios';

export type LoginCredentials = {
  email: string;
  password: string;
  token: any;
  provider: string;
};

export type RegisterCredentials = {
  email: string;
  password: string;
  first_name: string;
  mobile_no: string;
  last_name: string;
  selectedProviderId: string;
};

export type UserResponse = {
  avatar_url: string;
  company_name: string;
  created_date: string;
  email: string;
  first_name: string;
  last_name: string;
  mobile_no: string;
  modified_date: string;
  token: string;
  message: string;
  user_id: string;
  referralCode: string;
};
export type Token = {
  token: string;
};
type VerifyTokenPayload = {
  verifyToken: string;
};
type TokenPayload = {
  token: string;
  selectedProviderId?: any;
};
type ForgotPwdPayload = {
  email: string;
};
type resetPwdPayload = {
  email: string | null | undefined;
  password: string;
  resetToken: string | null | undefined;
};

export type UpdateUserProfilePayload = {
  first_name: string;
  last_name: string;
};

export const loginWithEmailAndPassword = (data: any): Promise<any> => {
  return axios.post('/user/login', data);
};

export const registerWithEmailAndPassword = (data: RegisterCredentials): Promise<UserResponse> => {
  return axios.post('/user/register', data);
};

export const getUserProfile = () => {
  return axios.get('/user/auth/me');
};

export const updateUserProfile = (payload: UpdateUserProfilePayload): Promise<any> => {
  return axios.post('/user/update_user', payload);
};

export const confirmVerifyTokenAPI = (payload: TokenPayload) => {
  return axios.post('user/confirm-verify-email', payload);
};
export const confirmEmail = (payload: VerifyTokenPayload) => {
  return axios.post('user/confirm-email', payload);
};

export const forgotPasswordAPI = (payload: ForgotPwdPayload) => {
  return axios.post('user/forgot-password', payload);
};

export const resetPasswordAPI = (payload: resetPwdPayload) => {
  return axios.patch('user/reset-password', payload);
};
export const loginWithGoogle = (data: Token): Promise<any> => {
  return axios.get('/user/auth/google', {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      authorization: `${data}`,
    },
  });
};

export const logoutWithGoogle = (): Promise<any> => {
  return axios.get('/user/logout');
};

export const loginByMobileNumber = (payload: any) => {
  return axios.post('user/loginByMobileNumber', payload);
};

export const validateOtp = (payload: any) => {
  return axios.post('user/validateOtp', payload);
};

export const registerByMobile = (payload: any) => {
  return axios.post('user/registerByMobileNumber', payload);
};
export const checkPasswordAPI = (): Promise<any> => {
  return axios.post('user/change_password');
};
export const resetPasswordUpdateAPI = (payload: any): Promise<any> => {
  return axios.post('user/reset_password', payload);
};
export const createPasswordAPI = (payload: any): Promise<any> => {
  return axios.post('user/set_password', payload);
};
export const contactUsAPI = (payload: any): Promise<any> => {
  return axios.post('contact/contact_us', payload);
};
export const sendOtpForSignup = (payload: any): Promise<any> => {
  return axios.post('user/sendOtp', payload);
};
export const verifyOtpForSignup = (payload: any): Promise<any> => {
  return axios.put('user/customer/verifyOtp', payload);
};
export const saveToDB = (payload: any): Promise<any> => {
  return axios.post('marketing/saveToDb', payload);
};
