export const API_URL = process.env.REACT_APP_API_URL as string;
export const JWT_SECRET = '123456' as string;
export const FACEBOOK_APP_ID = process.env.REACT_APP_LOGIN_FACEBOOK_APPID as string;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
export const GOOGLE_TOKEN = process.env.GOOGLE_ACCESS_TOKEN as string;
export const FB_TOKEN = process.env.FB_ACCESS_TOKEN as string;
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI as string;
export const FB_URL = process.env.REACT_APP_FB_URL as string;
export const INSTAGRAM_URL = process.env.REACT_APP_INSTAGRAM_URL as string;
export const TWITTER_URL = process.env.REACT_APP_TWITTER_URL as string;
export const LINKEDIN_URL = process.env.REACT_APP_LINKEDIN_URL as string;
export const YOUTUBE_URL = process.env.REACT_APP_YOUTUBE_URL as string;

export const API_KEY = process.env.REACT_APP_API_KEY as string;
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN as string;
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID as string;
export const STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET as string;
export const MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID as string;
export const APP_ID = process.env.REACT_APP_APP_ID as string;
export const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID as string;
export const GEO_API_KEY = process.env.REACT_APP_GEO_API_KEY as string;
export const INSURANCE_ACTIVE = process.env.REACT_APP_INSURANCE_ACTIVE;
export const INDIAPOST_ACTIVE = process.env.REACT_APP_INDIAPOST_ACTIVE;
export const HYPERLOCAL_ACTIVE = process.env.REACT_APP_INDIAPOST_HYPERLOCAL_ACTIVE as string;
export const SITE_DISABLED = process.env.REACT_APP_SITE_DISABLED as string;
export const COMING_SOON = process.env.REACT_APP_COMING_SOON as string;
export const INTERNATIONAL_ACTIVE = process.env.REACT_APP_INTERNATIONAL_ACTIVE as string;
export const CUTTOFF_XPRESSBEES = process.env.REACT_APP_CUTTOFF_XPRESSBEES as string;
export const CUTTOFF_AMAZON = process.env.REACT_APP_CUTTOFF_AMAZON as string;
export const CUTTOFF_CRITICALOG = process.env.REACT_APP_CUTTOFF_CRITICALOG as string;
export const CUTTOFF_SHADOWFAX = process.env.REACT_APP_CUTTOFF_SHADOWFAX as string;
export const CUTTOFF_ECOM_EXPRESS = process.env.REACT_APP_CUTTOFF_ECOM_EXPRESS as string;
export const CUTTOFF_DELHIVERY = process.env.REACT_APP_CUTTOFF_DELHIVERY as string;
export const CUTTOFF_DTDC = process.env.REACT_APP_CUTTOFF_DTDC as string;
export const CUTTOFF_DEFAULT = process.env.REACT_APP_CUTTOFF_DEFAULT as string;
export const CUTOFF_INDIAPOST_QWQER = process.env.REACT_APP_CUTOFF_INDIAPOST_QWQER as string;
export const CUTOFF_INDIAPOST_DUNZO = process.env.REACT_APP_CUTOFF_INDIAPOST_DUNZO as string;

export const CUTOFF_INDIAPOST_SELF = process.env.REACT_APP_CUTOFF_INDIAPOST_SELF as string;

export const CUTTOFF_SHREEMARUTI = process.env.REACT_APP_CUTTOFF_SHREEMARUTI as string;
export const MARQUEE_TEXT = process.env.REACT_APP_MARQUEE_TEXT as string;
export const CAMPAIGN_TEXT = 'campaign';
export const REFERRAL_SCHEME_ENABLE = process.env.REACT_APP_REFERRAL_SCHEME_ENABLE as string;
export const REFERRAL_SCHEME_TEXT = process.env.REACT_APP_REFERRAL_SCHEME_TEXT as string;
export const REFERRAL_SCHEME_LINK = process.env.REACT_APP_REFERRAL_SCHEME_LINK as string;
export const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GTM_ID as string;
export const KYC_ENABLE = process.env.REACT_APP_KYC_ENABLE as string;
export const DIGIO_ENV = process.env.REACT_APP_DIGIO_ENV as string;
export const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL as string;
export const CUTTOFF_GATI = process.env.REACT_APP_CUTTOFF_GATI as string;
export const SEARCH_BUTTON_FOOTNOTE = process.env.REACT_APP_SEARCH_BUTTON_FOOTNOTE as string;
export const SEARCH_BUTTON_TEXT = process.env.REACT_APP_SEARCH_BUTTON_TEXT as string;
export const GTM_ID = process.env.REACT_APP_GTM_ID as string;
export const GTM_AUTH = process.env.REACT_APP_GTM_AUTH as string;
export const GTM_PREVIEW = process.env.REACT_APP_GTM_PREVIEW as string;
export const COREYO_MAIN_PORTAL = process.env.REACT_APP_COREYO_MAIN_PORTAL as string;
export const MULTIPACKAGE = process.env.REACT_APP_MULTIPACKAGE as string;
export const TOGGLE_INDIAPOST_API = process.env.REACT_APP_TOGGLE_INDIAPOST_API as string;
export const RAZORPAY_ENABLED = process.env.REACT_APP_RAZORPAY_ENABLED as string;
