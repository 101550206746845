import React from 'react';
import TagManager from 'react-gtm-module';

import { GTM_AUTH, GTM_ID, GTM_PREVIEW } from './config';
import { AppProvider } from './context';
import { AppRoutes } from './routes';

function App() {
  const tagManagerArgs = {
    gtmId: GTM_ID,
    auth: GTM_AUTH,
    preview: GTM_PREVIEW,
  };

  React.useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  });
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
